import React from 'react';

function JoinedUsersList({ joinedUsers }) {
    return (
        <div className="card mb-3">
            <h2>Entradas</h2>
            <div className="card-body">
                <ul className="list-group joined">
                    {Array.isArray(joinedUsers) ? (
                        joinedUsers.map((join, index) => (
                            <li key={index} className="list-group-item">
                                <em className="d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm">({join.timestamp})</em>
                                <img className="rounded-circle" src={join.pictureLink} alt={join.profileName}
                                     width="40"/>
                                <strong><a
                                    className="link-dark d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm"
                                    href={`https://tiktok.com/@${join.username}`}  target="_blank" rel="noreferrer">{join.username}</a></strong> entrou na
                                live
                            </li>
                        ))
                    ) : (
                        <p>Nenhuma entrada recente disponível.</p>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default JoinedUsersList;