// AlertBanner.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './AuthContext';

const AlertBanner = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { user } = useAuth();

    // Ocultar banner quando usuário estiver logado
    useEffect(() => {
        if (user) {
            setIsVisible(false);
        }
    }, [user]);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible || user) {
        return null;
    }

    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <div>
                    <strong>ATENÇÃO:</strong> Devido a uma atualização mal sucedida, nosso banco de dados foi apagado. Por favor, registre seu usuário novamente para continuar utilizando a plataforma.
                </div>
            </div>
            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
        </div>
    );
};

export default AlertBanner;