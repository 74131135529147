import React from 'react';

function LikesList({ likesInfo }) {
    return (
        <div className="card mb-3">
            <h2>Curtidas</h2>
            <div className="card-body">
                <ul className="list-group likes">
                    {Array.isArray(likesInfo) ? (
                        likesInfo.map((like, index) => (
                            <li key={index} className="list-group-item">
                                <em className="d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm">({like.timestamp})</em>
                                <img className="rounded-circle" src={like.pictureLink} alt={like.profileName}
                                     width="40"/>
                                <strong><a
                                    className="link-dark d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm"
                                    href={`https://tiktok.com/@${like.username}`}  target="_blank" rel="noreferrer">{like.username}</a></strong> enviou {like.likes} curtidas
                            </li>
                        ))
                    ) : (
                        <p>Nenhuma curtida recente disponível.</p>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default LikesList;