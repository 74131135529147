import React, { useState, useRef, useEffect } from 'react';
import { Download } from 'lucide-react';
import html2canvas from 'html2canvas';

// Categorias de emojis populares no Brasil
const emojiCategories = {
    bandeiras: ['🇧🇷', '🏳️‍🌈', '🏁', '🇵🇹', '🇺🇾', '🇦🇷', '🇮🇹', '🇯🇵', '🇪🇸', '🇺🇸', '🇩🇪', '🇫🇷', '🇬🇧', '🇨🇴', '🇲🇽'],
    festas: ['🎉', '🎊', '🎈', '🎁', '🎂', '🎆', '🎇', '✨', '🎄', '🎃', '🎗️', '🎟️', '🎖️', '🏆', '🍾'],
    carnaval: ['💃', '🕺', '🥁', '🪘', '🎭', '🎪', '👑', '🎵', '🎶', '🎺', '🥳', '🦚', '🪩', '🎡', '🎨'],
    futebol: ['⚽', '🏆', '🥅', '👕', '📣', '🏟️', '🔴', '🔵', '⚫', '⚪', '🟢', '🟡', '🏅', '🎖️', '🎯'],
    comidas: ['🍖', '🍗', '🥩', '🍔', '🌭', '🍕', '🥐', '🧀', '🥞', '🍦', '🍰', '🍹', '🍺', '☕', '🍫'],
    animais: ['🐶', '🐱', '🐦', '🦜', '🦋', '🐠', '🐬', '🐢', '🦁', '🐯', '🐘', '🦒', '🐒', '🐊', '🦩'],
    natureza: ['🌴', '🌵', '🌊', '🏖️', '🏝️', '☀️', '🌈', '🌸', '🌹', '🌺', '🌻', '🌼', '🌷', '🍀', '🌿'],
    amor: ['❤️', '🧡', '💛', '💚', '💙', '💜', '🖤', '🤍', '💋', '💏', '💑', '💐', '😍', '🥰', '😘']
};

const BannerCreator = () => {
    const [backgroundColor, setBackgroundColor] = useState('#FF5722');
    const [borderStyle, setBorderStyle] = useState('dashed');
    const [borderColor, setBorderColor] = useState('#000000');
    const [borderWidth, setBorderWidth] = useState(5);
    const [fontFamily, setFontFamily] = useState('sans-serif');
    const [fontColor, setFontColor] = useState('#FFFFFF');
    const [textShadow, setTextShadow] = useState('none');
    const [textLines, setTextLines] = useState([
        { text: 'Live de Despedida de 2024!', emoji: '🇧🇷', fontSize: 18 },
        { text: 'Seja bem-vindo 2025!', emoji: '✨', fontSize: 18 },
        { text: 'Sorteio ao vivo!', emoji: '🎁', fontSize: 18 },
        { text: 'Me siga para mais lives!', emoji: '💚', fontSize: 18 }
    ]);
    const [bottomEmojis, setBottomEmojis] = useState('❤️ 🇧🇷 🎊');

    // Dimensões padrão para TikTok (9:16)
    const [canvasWidth, setCanvasWidth] = useState(1080);
    const [canvasHeight, setCanvasHeight] = useState(1920);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [currentEmojiField, setCurrentEmojiField] = useState(null);
    const [activeEmojiCategory, setActiveEmojiCategory] = useState(Object.keys(emojiCategories)[0]);

    const bannerRef = useRef(null);
    const canvasRef = useRef(null);
    const emojiPickerRef = useRef(null);

    // Fechar o seletor de emojis quando clicar fora dele
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTextChange = (index, field, value) => {
        const newTextLines = [...textLines];
        newTextLines[index][field] = value;
        setTextLines(newTextLines);
    };

    const openEmojiPicker = (type, index = null) => {
        setCurrentEmojiField({ type, index });
        setShowEmojiPicker(true);
    };

    const handleEmojiCategoryChange = (category) => {
        setActiveEmojiCategory(category);
    };

    const selectEmoji = (emoji) => {
        if (currentEmojiField) {
            if (currentEmojiField.type === 'line') {
                handleTextChange(currentEmojiField.index, 'emoji', emoji);
            } else if (currentEmojiField.type === 'bottom') {
                setBottomEmojis(bottomEmojis + ' ' + emoji);
            }
        }
    };

    const downloadBanner = () => {
        if (canvasRef.current && bannerRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            // Configurar dimensões do canvas
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // Limpar com fundo transparente
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Converter banner para imagem
            html2canvas(bannerRef.current).then(bannerCanvas => {
                // Posicionar o banner no canto superior direito com alguma margem
                const margin = 350;
                ctx.drawImage(bannerCanvas, canvas.width - bannerCanvas.width - margin/10, margin);

                // Converter para PNG e fazer download
                const link = document.createElement('a');
                link.download = 'banner-tiktok-1080x1920.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
            });
        }
    };

    return (
        <div className="mt-5 py-4 border-top">
            <div className="row mb-4">
                <div className="col-12 text-center">
                    <h2 className="h3 fw-bold">Criador de Banner para Lives do TikTok</h2>
                    <p className="text-muted">Crie banners personalizados para suas transmissões ao vivo (1080x1920)</p>
                </div>
            </div>

            <div className="row g-4">
                {/* Seção de Pré-visualização */}
                <div className="col-lg-6">
                    <h3 className="h5 mb-3 fw-bold">Pré-visualização</h3>
                    <div className="position-relative bg-light" style={{height: "400px", border: "1px solid #ddd", overflow: "hidden"}}>
                        <div className="d-flex justify-content-end p-3">
                            <div
                                ref={bannerRef}
                                className="p-3"
                                style={{
                                    backgroundColor: backgroundColor === 'transparent' ? 'transparent' : backgroundColor,
                                    borderColor: borderColor,
                                    borderStyle: borderStyle,
                                    borderWidth: `${borderWidth}px`
                                }}
                            >
                                {textLines.map((line, index) => (
                                    <div key={index} className="d-flex align-items-center my-1">
                                        <span className="me-1">{line.emoji}</span>
                                        <span style={{
                                            fontSize: `${line.fontSize}px`,
                                            fontWeight: "500",
                                            fontFamily: fontFamily,
                                            color: fontColor,
                                            textShadow: textShadow
                                        }}>{line.text}</span>
                                    </div>
                                ))}
                                <div className="mt-1 text-center" style={{
                                    fontFamily: fontFamily,
                                    fontSize: "16px",
                                    color: fontColor,
                                    textShadow: textShadow
                                }}>
                                    {bottomEmojis}
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute bottom-0 start-0 p-2 bg-light bg-opacity-75">
                            <small className="text-muted">Pré-visualização: A imagem final será 1080x1920 com o banner no topo direito</small>
                        </div>
                    </div>
                    <div className="mt-3 d-flex gap-3">
                        <button className="btn btn-success d-flex align-items-center gap-2" onClick={downloadBanner}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg>
                            Salvar imagem (1080x1920)
                        </button>
                        <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex gap-1 align-items-center">
                                <label className="form-label small mb-0">Largura:</label>
                                <input
                                    type="number"
                                    value={canvasWidth}
                                    onChange={(e) => setCanvasWidth(Number(e.target.value))}
                                    className="form-control form-control-sm"
                                    style={{width: "70px"}}
                                />
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <label className="form-label small mb-0">Altura:</label>
                                <input
                                    type="number"
                                    value={canvasHeight}
                                    onChange={(e) => setCanvasHeight(Number(e.target.value))}
                                    className="form-control form-control-sm"
                                    style={{width: "70px"}}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Canvas oculto para gerar a imagem final */}
                    <canvas ref={canvasRef} style={{display: 'none'}}></canvas>
                </div>

                {/* Seção de Controles */}
                <div className="col-lg-6">
                    <h3 className="h5 mb-3 fw-bold">Personalizar Banner</h3>
                    <div className="bg-light p-3 rounded">
                        <div className="mb-3">
                            <label className="form-label fw-bold">Fundo do Banner</label>
                            <div className="d-flex gap-2">
                                <select
                                    value={backgroundColor}
                                    onChange={(e) => setBackgroundColor(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="#FF5722">Laranja (Original)</option>
                                    <option value="transparent">Transparente</option>
                                    <option value="#009c3b">Verde Bandeira</option>
                                    <option value="#ffdf00">Amarelo Bandeira</option>
                                    <option value="#002776">Azul Bandeira</option>
                                    <option value="#e52b2b">Vermelho (Flamengo)</option>
                                    <option value="#006437">Verde (Palmeiras)</option>
                                    <option value="#0d80bf">Azul (Grêmio)</option>
                                </select>

                                {backgroundColor !== 'transparent' && (
                                    <input
                                        type="color"
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                        className="form-control form-control-color"
                                    />
                                )}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">Borda</label>
                            <div className="row g-2">
                                <div className="col-md-4">
                                    <label className="form-label small">Estilo</label>
                                    <select
                                        value={borderStyle}
                                        onChange={(e) => setBorderStyle(e.target.value)}
                                        className="form-select"
                                    >
                                        <option value="solid">Sólida</option>
                                        <option value="dashed">Tracejada</option>
                                        <option value="dotted">Pontilhada</option>
                                        <option value="double">Dupla</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label small">Cor</label>
                                    <input
                                        type="color"
                                        value={borderColor}
                                        onChange={(e) => setBorderColor(e.target.value)}
                                        className="form-control form-control-color w-100"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label small">Largura</label>
                                    <input
                                        type="number"
                                        value={borderWidth}
                                        onChange={(e) => setBorderWidth(Number(e.target.value))}
                                        className="form-control"
                                        min="0"
                                        max="20"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">Configurações da Fonte</label>
                            <div className="row g-2">
                                <div className="col-md-6">
                                    <label className="form-label small">Família da Fonte</label>
                                    <select
                                        value={fontFamily}
                                        onChange={(e) => setFontFamily(e.target.value)}
                                        className="form-select"
                                    >
                                        <option value="sans-serif">Sans-serif</option>
                                        <option value="serif">Serif</option>
                                        <option value="monospace">Monospace</option>
                                        <option value="'Comic Sans MS', cursive">Comic Sans</option>
                                        <option value="'Arial Black', Gadget, sans-serif">Arial Black</option>
                                        <option value="'Trebuchet MS', Helvetica, sans-serif">Trebuchet MS</option>
                                        <option value="Impact, Charcoal, sans-serif">Impact</option>
                                        <option value="'Lucida Console', Monaco, monospace">Lucida Console</option>
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label small">Cor do Texto</label>
                                    <div className="d-flex gap-2">
                                        <select
                                            value={fontColor}
                                            onChange={(e) => setFontColor(e.target.value)}
                                            className="form-select"
                                        >
                                            <option value="#FFFFFF">Branco</option>
                                            <option value="#000000">Preto</option>
                                            <option value="#FFFF00">Amarelo</option>
                                            <option value="#00FF00">Verde</option>
                                            <option value="#00FFFF">Ciano</option>
                                            <option value="#FF00FF">Magenta</option>
                                            <option value="#FF0000">Vermelho</option>
                                        </select>
                                        <input
                                            type="color"
                                            value={fontColor}
                                            onChange={(e) => setFontColor(e.target.value)}
                                            className="form-control form-control-color"
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label className="form-label small">Sombreamento</label>
                                    <select
                                        value={textShadow}
                                        onChange={(e) => setTextShadow(e.target.value)}
                                        className="form-select"
                                    >
                                        <option value="none">Nenhum</option>
                                        <option value="1px 1px 2px black">Leve</option>
                                        <option value="2px 2px 4px black">Médio</option>
                                        <option value="3px 3px 6px black">Forte</option>
                                        <option value="0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6">Neon Azul</option>
                                        <option value="0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff4da6, 0 0 20px #ff4da6">Neon Rosa</option>
                                        <option value="2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000">Contorno</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">Linhas de Texto</label>
                            {textLines.map((line, index) => (
                                <div key={index} className="card mb-2">
                                    <div className="card-body p-2">
                                        <div className="fw-bold small mb-2">Linha {index + 1}</div>
                                        <div className="row g-2">
                                            <div className="col-8">
                                                <label className="form-label small">Texto</label>
                                                <input
                                                    type="text"
                                                    value={line.text}
                                                    onChange={(e) => handleTextChange(index, 'text', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label className="form-label small">Emoji</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        value={line.emoji}
                                                        onChange={(e) => handleTextChange(index, 'emoji', e.target.value)}
                                                        className="form-control"
                                                        maxLength="3"
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() => openEmojiPicker('line', index)}
                                                    >
                                                        😀
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <label className="form-label small">Tamanho</label>
                                                <input
                                                    type="number"
                                                    value={line.fontSize}
                                                    onChange={(e) => handleTextChange(index, 'fontSize', Number(e.target.value))}
                                                    className="form-control"
                                                    min="12"
                                                    max="64"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">Emojis Inferiores</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={bottomEmojis}
                                    onChange={(e) => setBottomEmojis(e.target.value)}
                                    className="form-control"
                                />
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => openEmojiPicker('bottom')}
                                >
                                    😀
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Seletor de Emojis */}
            {showEmojiPicker && (
                <div
                    ref={emojiPickerRef}
                    className="position-fixed bg-white border rounded shadow p-3"
                    style={{
                        zIndex: 1050,
                        width: '320px',
                        maxHeight: '400px',
                        overflowY: 'auto',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h6 className="mb-0">Selecione um Emoji</h6>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setShowEmojiPicker(false)}
                        ></button>
                    </div>

                    <div className="nav nav-pills mb-3">
                        {Object.keys(emojiCategories).map((category) => (
                            <button
                                key={category}
                                className={`nav-link ${activeEmojiCategory === category ? 'active' : ''}`}
                                onClick={() => handleEmojiCategoryChange(category)}
                            >
                                {category.charAt(0).toUpperCase() + category.slice(1)}
                            </button>
                        ))}
                    </div>

                    <div className="emoji-container p-2 border rounded">
                        <div className="d-flex flex-wrap gap-2">
                            {emojiCategories[activeEmojiCategory].map((emoji, i) => (
                                <button
                                    key={i}
                                    className="btn btn-light p-1"
                                    style={{fontSize: '24px', minWidth: '40px', height: '40px'}}
                                    onClick={() => selectEmoji(emoji)}
                                >
                                    {emoji}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BannerCreator;