import React from 'react';

function CommentsList({ comments }) {
    return (
        <div className="card mb-3">
            <h2>Comentários</h2>
            <div className="card-body">
                <ul className="list-group">
                    {Array.isArray(comments)  ? (
                        comments.map((comment, index) => (
                            <li key={index} className="list-group-item">
                                <em className="d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm">({comment.timestamp})</em>
                                <img className="rounded-circle" src={comment.pictureLink} alt={comment.profileName}
                                     width="40"/>
                                <strong><a
                                    className="link-dark d-flex align-items-center bg-white px-3 py-1 rounded-pill shadow-sm"
                                    href={`https://tiktok.com/@${comment.username}`}  target="_blank" rel="noreferrer">{comment.username}</a></strong> {comment.text}
                            </li>
                        ))
                    ) : (
                        <p>Nenhum comentário disponível.</p>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default CommentsList;